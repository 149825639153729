.helpFormLink {
	border-bottom: 1px solid black;
	position: relative;
}

.helpFormLink:hover,
.helpFormLink:active {
	text-decoration: none;
}

.helpFormLink:after {
	content: "⌵";
	position: absolute;
	right: 0%;
	top: 28%;
	color: #12356a;
	transform: rotate(270deg);
	font-size: 1.875rem;
	font-weight: 900;
	padding-right: 5px;
}
