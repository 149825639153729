.pricingBanner {
	height: 430px;
}

.pricingServiceCard {
	margin-top: -233px;
}

@media screen and (max-width: 800px) {
	.pricingServiceCard {
		margin-top: 0vw;
	}
	.pricingBanner {
	height: auto;
}
}

.package-heading {
	letter-spacing: 3.6px;
}

.package-details .package-btn {
	font-size: 1rem;
	box-shadow: 2px 4px 8px #00000029;
	border-radius: 28px;
}

.customButton.package-btn {
	font-size: 1rem;
	font-weight: bold;
}

.pricing-table-heading-container {
	border-bottom: 1px solid #00000029;
}

.pricing-table-heading {
	letter-spacing: 3.6px;
}

.btn.btn-lg.pricing-package-button {
	font-size: 1.35rem;
	font-weight: bold;
	border-radius: 0%;
	/* all: unset; */
}
.customButton.pricing-package-button {
	height: auto;
}
.customButton.pricing-package-button::after {
	content: url("./images/icon-arrow-right.svg");
	font-size: 0;
	padding-left: 4px;
}

.package-details-container {
	padding-top: 5vw;
	padding-bottom: 5vw;
}

.package-details-heading {
	border-bottom: 1px solid gray;
}
