.card.serice-carousel-card {
	height: 275px;
	overflow: hidden;
	border: none;
	box-shadow: 0px 0px 15px #0000000f;
	border-radius: 10px;
}

.sub-heading {
	font: normal normal 900 22px/30px Source Sans Pro;
	letter-spacing: 0px;
	color: #12356a;
}

.serice-carousel-card .tab-data-content-container {
	padding: 2rem 1rem;
	padding-left: 2rem;
}
@media only screen and (max-width: 800px) {
 .card.serice-carousel-card {
	height: auto;
	
}

}