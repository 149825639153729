.logo-carousel .rec.rec-arrow.rec {
	border: 3px solid #12356a;
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 32px;
	font-size: 0.875rem;
	background-color: white;
}
